import React from 'react'
import './Home.scss'
import Login from '../../components/Login/Login'

const Home = () => {
  return (
    <>
      <Login />
    </>
  )
}

export default Home